import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { Link, useHistory } from 'react-router-dom'
import { FaArrowRight, FaEye, FaFacebookF, FaGoogle } from 'react-icons/fa'
import API from '../../API'
import Loader from '../../UI/CircularLoader/CircularLoader'
import Footer from '../../UI/Footer/Footer'
import classes from './Login.module.css'
import '../../Inputs.css'
import logo from '../../UI/Assets/Fonaton.svg'
import banner from '../../UI/Assets/login.png'
export default function Login() {
    const history = useHistory()
    const isLoggedIn = sessionStorage.getItem('isAuth') || localStorage.getItem('isAuth')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackContent, setSnackContent] = useState('')
    const [onLoadded, setOnLoadded] = useState(false)
    const [cloudflare, setCloudflare] = useState(false)
    const isDisabled = (
        email.length > 0 &&
        password.length > 0 &&
        cloudflare
    )
    /* Validation of cloudflare */
    useEffect(() => {
        window.testingCallback = async (token) => {
            const url = process.env.REACT_APP_VERIFY_CLOUDFLARE
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            });
            const data = await response.json();
            if (data.success) {
                setCloudflare(true);
            }
        };
    }, []);

    function handleLogin(e) {
        e.preventDefault()
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/donatives/benefactors/login/`,{ // To circumvent the 401 interceptor and display the error message
            email: email,
            password: password
        })
            .then(res => {
                console.log(res)
                setLoading(false)
                sessionStorage.setItem('isAuth', 'true')
                sessionStorage.setItem('access', res.data.access)
                sessionStorage.setItem('refresh', res.data.refresh)
                if(remember){
                    localStorage.setItem('isAuth', 'true')
                    localStorage.setItem('access', res.data.access)
                    localStorage.setItem('refresh', res.data.refresh)
                    localStorage.setItem('remember', 'true')
                }
                history.push('/perfil')
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                if (err.response) {
                    console.log(err.response)
                    setSnackContent(err.response.data.errors[0])
                    setOpenSnack(true)
                } else {
                    setSnackContent('Error de conexión. Intente de nuevo.')
                    setOpenSnack(true)
                }
            })
    }
    function responseGoogle(e) {
        const data = {
            provider_name: 'google',
            provider_id: e.profileObj.googleId,
            email: e.profileObj.email,
            first_name: e.profileObj.givenName,
            last_name: e.profileObj.familyName,
        }

        API.post('/donatives/benefactors/social_network_access_benefactor/', data)
            .then(res => {
                localStorage.setItem('isAuth', 'true')
                localStorage.setItem('access', res.data.access)
                localStorage.setItem('refresh', res.data.refresh)
                localStorage.setItem('remember', 'true')
                history.push('/perfil')
            })
            .catch(err => {
                console.log(err)
                setSnackContent('Hubo un error a la hora de registrarlo, intente de nuevo.')
                setOpenSnack(true)
            })
    }
    function failureGoogle(e) {
        if (!onLoadded) return
        setSnackContent('Problema al intentar establecer comunicación con Google, intente de nuevo más tarde.')
        setOpenSnack(true)
    }
    function responseFacebook(e) {
        const data = {
            provider_name: 'facebook',
            provider_id: e.userID,
            email: e.email,
            first_name: e.name.split(' ')[0],
            last_name: e.name.split(' ')[1],
        }

        API.post('/donatives/benefactors/social_network_access_benefactor/', data)
            .then(res => {
                console.log(res)
                localStorage.setItem('isAuth', 'true')
                localStorage.setItem('access', res.data.access)
                localStorage.setItem('refresh', res.data.refresh)
                localStorage.setItem('remember', 'true')
                history.push('/perfil')
            })
            .catch(err => {
                setSnackContent('Hubo un error a la hora de registrarlo, intente de nuevo.')
                setOpenSnack(true)
            })
    }
    if (isLoggedIn) {
        history.push('/')
    }
    return (
        <div>
            <Snackbar
                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
                open={openSnack}
                autoHideDuration={7000}
                onClose={() => setOpenSnack(false)}
                message="Usuario Creado">
                <SnackbarContent
                    style={{ backgroundColor: '#f43f5e', }}
                    message={
                        <span style={{ fontWeight: '700', color: '#4c0519', }}>
                            {snackContent}
                        </span>
                    }/>
            </Snackbar>
            <div>
                <div className={classes.login_container}>
                    <img src={logo} alt="logo"/>
                </div>
                <div className={classes.main_container}>
                    <form>
                        <div>
                            <h1>Bienvenido</h1>
                            <p>Accede a tu cuenta</p>
                        </div>
                        <div className={classes.form_group}>
                            <label className={classes.label_form} htmlFor="email">Correo</label>
                            <input placeholder="Escribe tu correo electronico" type="email"
                                   onChange={(e) => setEmail(e.target.value)} name="email" type="email"/>
                        </div>
                        <div className={classes.form_group}>
                            <label className={classes.label_form} htmlFor="password">Contraseña</label>
                            <input placeholder="Escribe tu contraseña" type="password"
                                   onChange={(e) => setPassword(e.target.value)} name="password"
                                   type={showPassword ? "text" : "password"}/>
                            <i className={classes.password_function} onClick={() => setShowPassword(!showPassword)}>
                                <FaEye/></i>
                        </div>
                        <div className={classes.form_row}>
                            <label className="b-contain">
                                <span>Recordar mi sesión</span>
                                <input onChange={() => setRemember(!remember)} checked={remember} type="checkbox"/>
                                <div className="b-input" style={{margin: '0px'}}></div>
                            </label>
                            <div className="b-contain">
                                <span
                                    style={{cursor: 'pointer'}}
                                    className={classes.forgot}
                                    onClick={() => history.push('/recuperar')}>
                                    Olvidé mi contraseña
                                </span>
                            </div>
                        </div>
                        <div>
                            {/* <div className={classes.fButton}>
                                <span ><FaFacebookF /></span>Regístrate con Facebook
                            </div> */}
                            {
                                /**
                                 <FacebookLogin
                                 appId={process.env.REACT_APP_FB_APP_ID}
                                 autoLoad={false}
                                 fields="name, email"
                                 callback={responseFacebook}
                                 render={renderProps => (
                                 <div
                                 onClick={renderProps.onClick}
                                 className={classes.fButton}
                                 style={{ cursor: 'pointer' }}
                                 >
                                 <span ><FaFacebookF /></span>Inicia sesión con Facebook
                                 </div>
                                 )}
                                 />
                                 */
                            }
                            {/* <div className={classes.gButton}>
                                <span><FaGoogle /></span> Regístrate con Google
                            </div> */}
                            {
                                /**
                                 *
                                 * <GoogleLogin
                                 clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                 render={renderProps => (
                                 <div
                                 onClick={() => {
                                 renderProps.onClick()
                                 setOnLoadded(true)
                                 }}
                                 className={classes.gButton}
                                 style={{ marginTop: '5px', cursor: 'pointer' }}
                                 >
                                 <span><FaGoogle /></span> Inicia sesión con Google
                                 </div>
                                 )}
                                 onAutoLoadFinished={() => setOnLoadded(true)}
                                 buttonText="Login"
                                 onSuccess={responseGoogle}
                                 onFailure={failureGoogle}
                                 cookiePolicy={'single_host_origin'}
                                 />
                                 */
                            }
                        </div>

                        <div
                            className="cf-turnstile"
                            data-sitekey="0x4AAAAAAA1a-IMlQ0ftE7db"
                            data-callback="testingCallback"
                        ></div>
                        <div className={classes.form_row}>
                            <a className={classes.button_submit} style={{textDecoration:'none'}} href="https://donar.fonaton.mx/registro">Regístrate <FaArrowRight/></a>
                            {loading ? <Loader/> :
                                <button
                                    className={classes.button_standar}
                                    onClick={handleLogin}
                                    disabled={!isDisabled}>
                                    Iniciar sesión
                                </button>}
                        </div>
                    </form>
                    <div className={classes.banner_login}>
                        <img src={banner} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}