import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import API from '../../API'
import Logo from '../Assets/Fonaton.svg'
import placeholder from '../Assets/user_placeholder.png'
import classes from './Navbar.module.css'
import Sidebar from "../Sidebar/Sidebar";
export default function Navbar() {
    let history = useHistory()
    const loggedIn = sessionStorage.getItem('isAuth') || localStorage.getItem('isAuth')
    const [photo, setPhoto] = useState(null)
    useEffect(() => {
        if (loggedIn) {
            let authUseEffect
            let decoded
            if (localStorage.getItem('remember') === 'true') {
                authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
                decoded = jwtDecode(localStorage.getItem('access'))
            } else {
                authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
                decoded = jwtDecode(sessionStorage.getItem('access'))
            }
            API.get(`/donatives/benefactors/${decoded.user_id}/`, {
                headers: authUseEffect
            })
                .then(res => {
                    setPhoto(res.data.photo)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [loggedIn])
    return (
        <nav className={classes.navbar}>
            <img
                src={Logo}
                className={classes.logo}
                style={{ cursor: 'pointer' }}
                alt="Fonaton"
                onClick={loggedIn ? () => history.push('/perfil') : () => history.push('/')}
            />
            <div className={classes.session}>
                {loggedIn ? (
                        <>
                            <img className={classes.profile} src={photo ? photo : placeholder} onClick={() => history.push('/perfil')} alt="profile" />
                            <button className={classes.sessionbutton} onClick={() => history.push('/perfil/donar')}>Donar</button>
                            <Sidebar />
                        </>
                    ) :
                    <a className={classes.sessionbutton} style={{textDecoration:'none'}} href="https://donar.fonaton.mx/login">
                        Iniciar sesión
                    </a>}
            </div>
            {/*<Sidebar/>*/}
        </nav>
    )
}